<template>
  <!--begin::Menu-->
  <div
    class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold py-4 fs-6 w-275px"
    data-kt-menu="true"
  >
    <!--begin::Menu item-->
    <div class="menu-item px-3">
      <div class="menu-content d-flex align-items-center px-3">
        <!--begin::Avatar-->
        
        <div class="symbol symbol-50px me-5">
          <span 
            v-if="userStore.user.photo_url"
            class="symbol-label bg-dark text-inverse-info" 
            :style="`background-image: url('${ getAssetUrl(userStore.user.photo_url) }')`"
          ></span>
            <span v-else-if="userStore.user.name" class="symbol-label bg-dark text-inverse-info">
            {{ userStore.user.name.slice(0, 1).toUpperCase() }}
          </span>
          <span v-else-if="userStore.user.email" class="symbol-label bg-dark text-inverse-info">
            {{ userStore.user.email.slice(0, 1).toUpperCase() }}
          </span>
          <span v-else class="symbol-label bg-dark text-inverse-info">H</span>
        </div>
        <!--end::Avatar-->

        <!--begin::Username-->
        <div class="d-flex flex-column">
          <div v-if="userStore.user" class="fw-bold d-flex align-items-center">
            <span v-if="userStore.user.name" class="fs-5 me-2">{{ userStore.user.name }}</span>
            <span
              v-if="userStore.user.subscription"
              class="badge fw-semibold fs-8 px-2 py-1"
              :class="`badge-light-${userStore.user.subscription.color}`"
            >{{ userStore.user.subscription.type }}</span>
          </div>
          <div class="fw-semibold text-muted text-hover-primary fs-7">{{ userStore.user.email }}</div>
        </div>
        <!--end::Username-->
      </div>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu separator-->
    <div class="separator my-2"></div>
    <!--end::Menu separator-->

    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <router-link to="/account/overview" class="menu-link px-5">
        {{ $t('menu.userAccount.myProfile') }}
      </router-link>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
<!--     <div class="menu-item px-5">
      <router-link to="/search" class="menu-link px-5">
        <span class="menu-text">{{ $t('menu.userAccount.mySearches') }}</span>
        <span class="menu-badge">
          <span class="badge badge-light-primary badge-circle fw-bold fs-7">3</span>
        </span>
      </router-link>
    </div> -->
    <!--end::Menu item-->

    <!--begin::Menu item-->
<!--     <div class="menu-item px-5">
      <router-link to="/groups" class="menu-link px-5">
        <span class="menu-text">{{ $t('menu.userAccount.myGroups') }}</span>
        <span class="menu-badge">
          <span class="badge badge-light-primary badge-circle fw-bold fs-7">7</span>
        </span>
      </router-link>
    </div> -->
    <!--end::Menu item-->

    <!--begin::Menu separator-->
<!--     <div class="separator my-2"></div> -->
    <!--end::Menu separator-->

    <!--begin::Menu item-->
    <div
      class="menu-item px-5"
      data-kt-menu-trigger="hover"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="center, top"
    >
      <div class="menu-link px-5">
        <span class="menu-title position-relative">
          {{ $t('menu.userAccount.language') }}
          <span
            class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0"
          >
            {{ currentLangugeLocale.name }}
            <img
              class="w-15px h-15px rounded-1 ms-2"
              :src="currentLangugeLocale.flag"
            />
          </span>
        </span>
      </div>

      <!--begin::Menu sub-->
      <div class="menu-sub menu-sub-dropdown w-175px py-4">
        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a
            @click="setLang('en')"
            href="#"
            class="menu-link d-flex px-5"
            :class="{ active: currentLanguage === 'en' }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                :src="getAssetPath('media/flags/united-states.svg')"
              />
            </span>
            English
          </a>
        </div>
        <!--end::Menu item-->

        <!--begin::Menu item-->
        <div class="menu-item px-3">
          <a
            @click="setLang('ru')"
            href="#"
            class="menu-link d-flex px-5"
            :class="{ active: currentLanguage === 'ru' }"
          >
            <span class="symbol symbol-20px me-4">
              <img
                class="rounded-1"
                :src="getAssetPath('media/flags/russia.svg')"
              />
            </span>
            Русский
          </a>
        </div>
        <!--end::Menu item-->
      </div>
      <!--end::Menu sub-->
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-5 my-1">
      <router-link to="/account/settings" class="menu-link px-5">
        {{ $t('menu.userAccount.accountSettings') }}
      </router-link>
    </div>
    <!--end::Menu item-->

    <!--begin::Menu item-->
    <div class="menu-item px-5">
      <div @click="signOut()" class="menu-link px-5"> {{ $t('menu.userAccount.signOut') }} </div>
    </div>
    <!--end::Menu item-->
  </div>
  <!--end::Menu-->
</template>

<script lang="ts">
import { getAssetPath, getAssetUrl } from "@/core/helpers/assets";
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "kt-user-menu",
  components: {},
  setup() {
    const router = useRouter();
    const { t, locale } = useI18n();
    const userStore = useAuthStore();

    const countries = {
      en: {
        flag: getAssetPath("media/flags/united-states.svg"),
        name: "English",
      },
      ru: {
        flag: getAssetPath("media/flags/russia.svg"),
        name: "Русский",
      },
    };

    const signOut = () => {
      userStore.logout();
      router.push({ name: "sign-in" });
    };

    const setLang = (lang: string) => {
      localStorage.setItem("lang", lang);
      locale.value = lang;
    };
    
    const currentLanguage = computed(() => {
      return locale.value;
    });
    
    const currentLangugeLocale = computed(() => {
      return countries[locale.value as keyof typeof countries];
    });

    return {
      userStore,
      signOut,
      setLang,
      currentLanguage,
      currentLangugeLocale,
      countries,
      getAssetPath,
      getAssetUrl,
    };
  },
});
</script>
