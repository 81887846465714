<template>
  <div class="d-flex flex-column flex-center flex-column-fluid">
    <!--begin::Content-->
    <div class="d-flex flex-column flex-center text-center p-10">
      <!--begin::Wrapper-->
      <div class="card card-flush w-lg-650px py-5">
        <div class="card-body py-15 py-lg-20">
          <!--begin::Logo-->
          <div class="mb-14">
            <router-link to="/">
              <img
                :src="getAssetPath('media/logos/default.svg')"
                class="h-40px theme-light-show"
                alt="Palert light logo"
              /> 
              <img
                :src="getAssetPath('media/logos/default-dark.svg')"
                class="h-40px theme-dark-show"
                alt="Palert dark logo"
              /> 
            </router-link>
          </div>
          <!--end::Logo-->

          <div v-if="!isLoading" class="d-flex flex-column flex-center">
            <!--begin::Title-->
            <h1 class="fw-bolder text-gray-900 mb-4">
              {{ error[0] ? $t('pages.authentication.verifyEmail.titleError') : $t('pages.authentication.verifyEmail.title') }}
            </h1>
            <!--end::Title-->
            
            <!--begin::Text-->
            <div class="fw-semibold fs-5 text-gray-500 mb-7 mw-500px">
              {{ error[0] ?? $t('pages.authentication.verifyEmail.subtitle') }}
              
              <!--begin::Process-->
              <span v-if="error.length === 0" class="d-block fw-semibold fs-6 text-gray-500 mt-3">
                {{ $t('pages.authentication.verifyEmail.processGoHome', {second: second}) }}
              </span>
              <!--end::Process-->

            </div>
            <!--end::Text-->
          </div>
  
          <div v-else class="d-flex flex-center align-items-center pt-5 pb-13">
            <div class="spinner-border text-dark me-4" role="status"></div>
            <span class="fw-semibold fs-6 text-gray-600">Подтверждения эл. почты...</span>
          </div>

          <!--begin::Illustration-->
          <div class="mb-7">
            <img
              :src="getAssetPath('media/auth/please-verify-your-email.png')"
              class="mw-100 mh-300px theme-light-show"
              alt=""
            />
            <img
              :src="getAssetPath('media/auth/please-verify-your-email-dark.png')"
              class="mw-100 mh-300px theme-dark-show"
              alt=""
            />
          </div>
          <!--end::Illustration-->
          
          <!--begin::Link-->
          <div class="mb-0">
            <router-link to="/dashboard" class="btn btn-sm btn-primary">
              {{ $t('goHome')}}
            </router-link>
          </div>
          <!--end::Link-->
        </div>
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts" setup>
import { getAssetPath, getIllustrationsPath } from "@/core/helpers/assets";
import { onMounted, ref } from "vue";
import { useBodyStore } from "@/stores/body";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";
import { themeMode } from "@/layouts/default-layout/config/helper";
import LayoutService from "@/core/services/LayoutService";

const bodyStore = useBodyStore();
const userStore = useAuthStore();
const router = useRouter();

const error = ref<object|null>(null);
const isLoading = ref<boolean>(true);
const second = ref<number>(10);

const bgImage =
  themeMode.value !== "dark"
    ? getAssetPath("media/auth/bg5.webp")
    : getAssetPath("media/auth/bg5-dark.webp");

const processPage = async () => {
  isLoading.value = true;

  const urlParams = new URLSearchParams(window.location.search);
  const user_id = urlParams.get('user_id') || '';
  const confirm_code = urlParams.get('confirm_code') || '';

  // Отправляем запрос на проверку почты
  await userStore.verifyEmail({ user_id, confirm_code });
  error.value = Object.values(userStore.errors);

  isLoading.value = false;
  
  if (error.value.length === 0) {
    let countdownInterval = setInterval(() => {
      second.value -= 1;
      if (second.value <= 0) {
        clearInterval(countdownInterval); // Остановить обратный отсчет
        router.push({ name: "dashboard" });
      }
    }, 1000);
  }

  userStore.errors = {}; // Очищаем ошибки
};
    
onMounted(() => {
  LayoutService.emptyElementClassesAndAttributes(document.body);

  bodyStore.addBodyClassname("bg-body");
  bodyStore.addBodyAttribute({
    qualifiedName: "style",
    value: `background-image: url("${bgImage}")`,
  });
  
  processPage();
});
</script>