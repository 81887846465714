import { createI18n } from "vue-i18n";

// Импорт локализаций
import en from '@/locales/en';
import ru from '@/locales/ru';

// Объединение всех сообщений локализации
const messages = {
  en,
  ru,
};

// Установка языка по умолчанию из localStorage или "ru"
const defaultLocale = localStorage.getItem("lang") || "ru";

// Создание экземпляра i18n
const i18n = createI18n({
  legacy: false,
  locale: defaultLocale,
  globalInjection: true,
  messages,
});

export default i18n;