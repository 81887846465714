<template>
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <div class="card-header min-h-60px">
      <h3 class="card-title fw-bold m-0">
        {{ $t('pages.account.layoutOverview.profileDetails') }}
      </h3>

      <router-link
        to="/account/settings"
        class="btn btn-sm btn-light align-self-center "
      >
        {{ $t('actions.edit') }}
      </router-link>
    </div>

    <div class="card-body p-9">
      <div class="row mb-7">
        <label class="col-lg-3 fw-semibold text-muted">
          {{ $t('pages.account.layoutOverview.fullName') }}
        </label>

        <div class="col-lg-9">
          <span class="fw-semibold fs-5">
            {{ userStore.user.name }} {{ userStore.user.last_name }}
          </span>
        </div>
      </div>

      <div class="row mb-7">
        <label class="col-lg-3 fw-semibold text-muted">
          {{ $t('city') }}
        </label>
      
        <div class="col-lg-9">
          <span class="fw-semibold fs-5">
            {{ userStore.user.city?.place }}
          </span>
        </div>
      </div>

      <div class="row mb-7">
        <label class="col-lg-3 fw-semibold text-muted">
          {{ $t('pages.authentication.email') }}
        </label>

        <div class="col-lg-9 fv-row">
          <span class="fw-semibold fs-5">
            {{ userStore.user.email }}
          </span>
        </div>
      </div>

      <div
        v-if="userStore.user.mobile_phone"
        class="row mb-7"
      >
        <label class="col-lg-3 fw-semibold text-muted">
          {{ $t('pages.account.layoutOverview.mobilePhone') }}
          <i
            class="fas fa-exclamation-circle ms-1 fs-7"
            v-tooltip data-bs-custom-class="tooltip-inverse"
            :title="$t('pages.account.layoutOverview.mobileTitle')"
          ></i>
        </label>

        <div class="col-lg-9 d-flex align-items-center">
          <span class="fw-semibold fs-5 me-2">{{ userStore.user.mobile_phone }}</span>
<!--           <span class="badge badge-sm badge-outline badge-success">Verified</span> -->
        </div>
      </div>
<!--
      <div class="row">
        <label class="col-lg-3 fw-semibold text-muted">
          {{ $t('pages.account.layoutOverview.communication') }}
        </label>

        <div class="col-lg-9">
          <span class="fw-semibold fs-5">
            {{ $t('pages.authentication.email') }}, {{ $t('messager.telegram') }}</span>
        </div>
      </div>
 -->
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useAuthStore } from "@/stores/auth";

const userStore = useAuthStore();
</script>
