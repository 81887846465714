<template>
  <!--begin::Step 2-->
  <div
    v-if="itemStore.platforms?.length > 0"
    data-kt-stepper-element="content"
  >
    <!--begin::Wrapper-->
    <div class="d-flex flex-column w-100">
      <div
        v-for="platform in itemStore.platforms"
        :key="platform.id"
        class="d-flex flex-column mb-5 mb-md-7 fv-row"
      >
        <div 
          v-if="localItemData.platform?.[platform.id]?.links"
          v-for="(link, linkID) in localItemData.platform[platform.id].links" 
          :key="`platform_${platform.id}_${linkID}`" 
          class="d-flex flex-column mb-2"
        >
          <div class="d-flex align-items-center gap-2">
            <!-- Кнопка клика по ссылке -->
            <button
              type="button"
              :class="[
                'btn btn-icon',
                !isLinkValid(platform.id, linkID) ? 'btn-light-secondary' : 'btn-light-success'
              ]"
              @click="clickLink(platform.id, linkID)"
              :disabled="!isLinkValid(platform.id, linkID)"
            >
              <KTIcon icon-name="click" icon-class="fs-3" />
            </button>

            <!-- Поле для ввода ссылки -->
            <Field
              type="text"
              class="form-control"
              :placeholder="platform.url"
              :name="`platform.platform_${platform.id}.key_${linkID}`"
              v-model="localItemData.platform[platform.id].links[linkID]"
            />

            <!-- Кнопка для добавления новой ссылки -->
            <button
              v-if="Object.keys(localItemData.platform[platform.id].links).indexOf(linkID) === 0"
              type="button"
              class="btn btn-icon btn-light-primary"
              @click="addLink(platform.id)"
              :disabled="localItemData.platform[platform.id] && Object.keys(localItemData.platform[platform.id].links).length >= (userStore.user?.subscription ? userStore.user?.subscription.maxLink : 1)"
            >
              <KTIcon icon-name="plus" icon-class="fs-3" />
            </button>

            <!-- Кнопка для удаления ссылки -->
            <button
              v-else
              type="button"
              class="btn btn-icon btn-light-danger"
              @click="removeLink(platform.id, linkID)"
            >
              <KTIcon icon-name="trash" icon-class="fs-3" />
            </button>
          </div>

          <!-- Сообщение об ошибке валидации -->
          <ErrorMessage 
            class="fv-plugins-message-container invalid-feedback d-block mt-1"
            :key="`platform_${platform.id}_${linkID}`" 
            :name="`platform.platform_${platform.id}.key_${linkID}`" 
          />
        </div>

        <!-- Описание и ссылка на платформу -->
        <div class="text-muted fs-7">
          <span v-html="translatedLink(platform)"></span>
        </div>
      </div>
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Step 2-->
</template>

<script lang="ts">
import { defineComponent, watch, computed } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/auth";
import { 
  generateLink, 
  cleanLinkInput,
  isUrlMatchingPlatform 
} from "@/utils/helpers/itemHelpers";

export default defineComponent({
  name: "item-modal-step2",
  components: {
    ErrorMessage,
    Field,
  },
  props: {
    localItemData: {
      type: Object,
      required: true
    },
    itemStore: {
      type: Object,
      required: true
    },
  },
  setup(props) {
    const { t } = useI18n();
    const userStore = useAuthStore();
    
    /**
     * Проверяет, является ли ссылка для заданной платформы и идентификатора ссылки валидной.
     * @param {string} platformID - Идентификатор платформы.
     * @param {string} linkID - Идентификатор ссылки.
     * @returns {boolean} true, если ссылка валидна.
     */
    const isLinkValid = computed(() => (platformID: string, linkID: string) => {
      if (!props.localItemData.platform) return false;
      const url = props.localItemData.platform[platformID]?.links[linkID];
      const platform = props.itemStore.platforms?.find(p => p.id === platformID);
    
      if (!url || !platform) return false;
    
      const finalUrl = generateLink(platform, url);
      return isUrlMatchingPlatform(finalUrl, platform);
    });

    /**
     * Добавляет новую ссылку для указанной платформы.
     * @param {string} platformID - Идентификатор платформы.
     */
    const addLink = (platformID: string) => {
      if (!platformID) return;

      const existingLinks = Object.keys(props.localItemData.platform[platformID].links);

      if (!existingLinks.length) {
        props.localItemData.platform[platformID].links = {'n0': ''};
      } else if (existingLinks.length < (userStore.user?.subscription ? userStore.user?.subscription.maxLink : 1)) {
        const newLinkID = generateNewLinkID(existingLinks);
        props.localItemData.platform[platformID].links[newLinkID] = '';
      }
    };

    /**
     * Генерирует новый идентификатор для ссылки на основе существующих идентификаторов.
     * @param {string[]} existingLinks - Массив существующих идентификаторов ссылок.
     * @returns {string} Новый идентификатор ссылки.
     */
    const generateNewLinkID = (existingLinks: string[]) => {
      const maxIndex = existingLinks.reduce((max, linkID) => {
        const match = linkID.match(/^n(\d+)$/);
        return match ? Math.max(max, parseInt(match[1], 10)) : max;
      }, -1);

      return `n${maxIndex + 1}`;
    };

    /**
     * Удаляет ссылку с указанным идентификатором.
     * @param {string} platformID - Идентификатор платформы.
     * @param {string} linkID - Идентификатор ссылки.
     */
    const removeLink = (platformID: string, linkID: string) => {
      if (Object.prototype.hasOwnProperty.call(props.localItemData.platform[platformID].links, linkID)) {
        delete props.localItemData.platform[platformID].links[linkID];
      }
    };

    /**
     * Открывает ссылку в новом окне.
     * @param {string} platformID - Идентификатор платформы.
     * @param {string} linkID - Идентификатор ссылки.
     */
    const clickLink = (platformID: string, linkID: string) => {
      const url = props.localItemData.platform[platformID]?.links?.[linkID];
      const platform = props.itemStore.platforms?.find((p) => p.id === platformID);

      if (platform && url) {
        const finalUrl = generateLink(platform, url);
        window.open(finalUrl, '_blank');
      }
    };

    /**
     * Создает HTML-ссылку для указанной платформы.
     * @param {Object} platform - Данные платформы.
     * @returns {string} HTML-ссылка для платформы.
     */
    const platformLink = (platform) => {
      return `<a href="${platform.url}" aria-label="${platform.title}" target="_blank">${platform.title}</a>`;
    };

    /**
     * Форматирует строку перевода с вставленной HTML-ссылкой.
     * @param {Object} platform - Данные платформы.
     * @returns {string} Строка перевода с HTML-ссылкой.
     */
    const translatedLink = (platform) => {
      return t('pages.items.modal.subLabel.link', { platform: platformLink(platform) });
    };

    // Наблюдатель для очистки и форматирования ссылок
    watch(() => props.localItemData.platform, (newLinks) => {
      if (newLinks && typeof newLinks === 'object') {
        Object.keys(newLinks).forEach(platformID => {
          const platformData = newLinks[platformID];
          if (platformData?.links) {
            Object.entries(platformData.links).forEach(([linkID, link]) => {
              if (link) props.localItemData.platform[platformID].links[linkID] = cleanLinkInput(link);
            });
          }
        });
      }
    }, { deep: true });

    return {
      userStore,
      isLinkValid,
      addLink,
      removeLink,
      clickLink,
      translatedLink,
    };
  },
});
</script>
