<template>
  <div class="card card-flush">
    <div class="card-header py-5 gap-0 gap-md-5">
      <div class="card-title">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bold fs-3 mb-1">
            {{ $t('pages.searchId.layoutAnalytics.bestGraph.title') }}
          </span>
          <span class="text-muted mt-1 fw-semibold fs-7">
            {{ $t('pages.searchId.layoutAnalytics.bestGraph.subtitle') }}
            <PriceFormatter
              :minPrice="minPrice ?? 0"
              :maxPrice="maxPrice ?? 0"
              :showCurrency="true"
              rangeCurrencyPosition="end"
            />
          </span>
        </h3>
      </div>
      
      <div class="card-toolbar" data-kt-buttons="true">
        <a
          v-for="(label, key, index) in periods"
          :key="key"
          :class="[
            'btn btn-sm btn-color-muted px-4',
            {
              'ms-2': index !== 0,
              'btn-active-primary active': period === key,
              'btn-active-light-primary': period !== key
            }
          ]"
          @click="updatePeriod(key)"
        >
          {{ label }}
        </a>
      </div>
    </div>
    
    <div class="card-body d-flex align-items-end p-0">
      <BestPrice :height="300" :data="dataChart" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from 'vue-router';
import { usePriceChartStore } from "@/stores/priceChart";

import BestPrice from "@/components/chart/BestPrice.vue";
import PriceFormatter from '@/components/formatters/PriceFormatter.vue';

export default defineComponent({
  name: "search-id-analytics",
  components: {
    BestPrice,
    PriceFormatter,
  },
  setup() {
    const { t, locale } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const dataChart = usePriceChartStore();
    const period = ref('month');

    // Массив с периодами и их названиями
    const periods = ref({
      // year: t('pages.searchId.layoutAnalytics.bestGraph.period.year'),
      month: t('pages.searchId.layoutAnalytics.bestGraph.period.month'),
      week: t('pages.searchId.layoutAnalytics.bestGraph.period.week'),
    });
    
    // Следим за изменением локали и обновляем названия периодов
    watch(locale, () => {
      periods.value = {
        month: t('pages.searchId.layoutAnalytics.bestGraph.period.month'),
        week: t('pages.searchId.layoutAnalytics.bestGraph.period.week'),
      };
    });

    const fetchChartData = async () => {
      await dataChart.fetchData({ id: Number(route.params.id), period: period.value });
      router.push({ hash: '#chart' }).then(() => {
        const element = document.getElementById('chart');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      });

    };

    const updatePeriod = async (newPeriod: string) => {
      if (period.value === newPeriod) {
        return;
      }
      period.value = newPeriod;
      await fetchChartData();
    };
    
    const minPrice = computed(() => {
      const prices = dataChart.dataObj?.price || [];
      const filteredPrices = prices.filter(price => price !== null && price !== undefined);
      
      // Если массив пустой, возвращаем null или другое значение
      if (filteredPrices.length === 0) return null; 
    
      return Math.min(...filteredPrices);
    });
    
    const maxPrice = computed(() => {
      const prices = dataChart.dataObj?.price || [];
      const filteredPrices = prices.filter(price => price !== null && price !== undefined);
      
      // Если массив пустой, возвращаем null или другое значение
      if (filteredPrices.length === 0) return null;
    
      return Math.max(...filteredPrices);
    });

    onMounted(() => {
      fetchChartData();
    });

    watch(
      () => [route.query.clear, route.fullPath],
      async ([newCleared, newPath], [oldCleared, oldPath]) => {
        if (newCleared !== oldCleared && newCleared) {
          await fetchChartData();
        } else if (newPath !== oldPath) {
          await dataChart.resetLoading();
        }
      }
    );
    
    return {
      dataChart,
      period,
      periods,
      updatePeriod,
      minPrice,
      maxPrice,
    };
  }
});
</script>
