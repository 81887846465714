import { defineStore } from 'pinia';
import i18n from "@/core/plugins/i18n";
import ApiService from '@/core/services/ApiService';

export const useAlertStore = defineStore('alert', {
  state: () => ({
    items: [] as any[],
    error: null as string | null,
    isLooked: false as boolean,
  }),

  actions: {
    setLooked(value: boolean) {
      this.isLooked = value;
    },

    async fetchData() {
      try {
        const { data } = await ApiService.get('pulse.table.alert.list.json');
        this.items = data.result;

        // Проверка элементов на наличие looked, отличного от '1'
        if (this.items.some(item => item.looked !== '1')) {
          this.setLooked(true);
        }
      } catch (error) {
        this.error = error.response?.data?.error_description || i18n.global.t('error.unknown');
      }
    },
    
    async checkView() {
      const ids = this.items.map(item => item.id);
      await ApiService.post('pulse.table.alert.check', { ids });
    }
  },
});
