<template>
  <div
    class="image-input image-input-outline mb-3"
    :class="{'image-input-empty image-input-placeholder': !imageSrc}"
    data-kt-image-input="true"
  >
    <div
      class="image-input-wrapper w-150px h-150px"
      :style="{
        backgroundImage: `url(${imageSrc})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat'
      }"
    ></div>
    <!--begin::Edit button-->
    <label
      class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
      data-kt-image-input-action="change"
      data-bs-toggle="tooltip"
      :aria-label="$t('components.ImageUploader.change')"
      :data-bs-original-title="$t('components.ImageUploader.change')"
      :title="$t('actions.edit')"
    >
      <KTIcon icon-name="pencil" icon-class="fs-6" />
        
      <!--begin::Inputs-->
      <input
        type="file"
        name="image"
        accept=".png, .jpg, .jpeg, .webp"
        @change="onFileChange"
        ref="fileInput"
      />
      <input type="hidden" name="image_remove" />
      <!--end::Inputs-->
    </label>
    <!--end::Edit button-->

    <!--begin::Remove button-->
    <span
      class="btn btn-icon btn-circle btn-color-muted btn-active-color-primary w-25px h-25px bg-body shadow"
      data-kt-image-input-action="remove"
      data-bs-toggle="tooltip"
      :aria-label="$t('components.ImageUploader.remove')"
      :data-bs-original-title="$t('components.ImageUploader.remove')"
      @click="removeImage"
      :title="$t('actions.delete')"
    >
      <i class="ki-outline ki-cross fs-2"></i>
    </span>
    <!--end::Remove button-->
  </div>
  <div class="text-muted fs-7">
    {{ $t('components.ImageUploader.subLabel_1') }}
    <br>
    {{ $t('components.ImageUploader.subLabel_2') }}
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { getAssetUrl } from "@/core/helpers/assets";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "ImageUploader",
  props: {
    modelValue: {
      type: String as PropType<string | null>,
      default: null,
    },
  },
  emits: ['update:modelValue', 'file-change'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const imageSrc = ref<string | null>(getImageSource(props.modelValue));
    const fileInput = ref<HTMLInputElement | null>(null);
    
    const isValidImage = (file: File) => {
      const validExtensions = ["image/png", "image/jpeg", "image/jpg", "image/webp"];
      return validExtensions.includes(file.type);
    };

    const onFileChange = (e: Event) => {
      const file = (e.target as HTMLInputElement).files?.[0];
      if (file && isValidImage(file)) {
        handleFileChange(file);
      } else {
        Swal.fire({
          text: t('components.ImageUploader.subLabel_2'),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t('messages.button.okGotIt'),
          heightAuto: false,
          customClass: {
            confirmButton: "btn btn-danger",
          },
        });
        (e.target as HTMLInputElement).value = '';
      }
    };

    const resetImage = () => {
      imageSrc.value = null;
    };
    
    const removeImage = () => {
      resetImage();
      handleFileChange('del');
      if (fileInput.value) {
        fileInput.value.value = '';
      }
    };
    
    const handleFileChange = (file: File | string) => {
      if (file === 'del') {
        imageSrc.value = null;
      } else if (file instanceof File) {
        imageSrc.value = URL.createObjectURL(file);
      }
      emit("update:modelValue", imageSrc.value); // Update v-model
      emit("file-change", file); // Emit the file-change event with the selected file
    };
        
    watch(() => props.modelValue, (newImage) => {
      imageSrc.value = getImageSource(newImage);
    });
    
    function getImageSource(image: string | null): string | null {
      if (image?.startsWith('blob:')) {
        return image;
      } else {
        return image ? getAssetUrl(image) : null;
      }
    }

    return {
      imageSrc,
      onFileChange,
      resetImage,
      removeImage,
      fileInput,
    };
  },
});
</script>
