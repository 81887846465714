<template>
  <!--begin::Step 1-->
  <div class="current" data-kt-stepper-element="content">
    <!--begin::Wrapper-->
    <div class="d-flex flex-column w-100">
      
      <!-- Поле для ввода названия поиска -->
      <div class="mb-5 mb-md-7 fv-row">
        <label class="form-label required">{{ $t('modal.label.title') }}</label>
        
        <Field
          type="text"
          class="form-control mb-2"
          :placeholder="$t('pages.items.modal.placeholder.title')"
          name="title"
          v-model="localItemData.title"
        />
        
        <div class="text-muted fs-7">
          {{ $t('pages.items.modal.subLabel.title') }}
        </div>
        <ErrorMessage class="fv-plugins-message-container invalid-feedback" name="title" />
      </div>

      <!-- Поле для ввода ожидаемой цены -->
      <div class="mb-2 mb-md-4 fv-row">
        <label class="form-label">{{ $t('pages.items.modal.label.wishprice') }}</label>
                      
        <CurrencyInput
          class="form-control mb-2"
          :placeholder="$t('pages.items.modal.placeholder.wishprice')"
          name="wishprice"
          v-model="localItemData.wishprice"
        />
        
        <div class="text-muted fs-7">
          {{ $t('pages.items.modal.subLabel.wishprice') }}
        </div>
      </div>

      <!-- Поле для выбора группы -->
      <div class="mb-5 mb-md-7 fv-row">
        <label class="form-label">{{ $t('pages.items.modal.label.group') }}</label>
        
        <GroupMultiselect
          class="py-3 pe-3 mb-2"
          v-model="localItemData.group"
          @update:typedChars="updateTypedCharsGroup"
          :maxLength="25"
        />
        
        <MaxLengthIndicator
          v-if="typedCharsGroup"
          :currentLength="typedCharsGroup"
          :maxLength="25"
        />
      
        <div class="text-muted fs-7">
          {{ itemStore.groups?.length > 0 ? $t('pages.items.modal.subLabel.group.select') : $t('pages.items.modal.subLabel.group.add') }}
        </div>
      </div>    

      <!-- Опция загрузки изображения -->
      <div class="d-flex flex-column mb-7 fv-row">
        <div class="d-flex flex-stack">
          <div class="me-5">
            <label class="form-label">{{ $t('modal.label.thumbnail') }}</label>              
            <div class="text-muted fs-7">
              {{ $t('pages.items.modal.subLabel.pict') }}
            </div>
          </div>
          <label class="form-check form-switch form-check-custom form-check-solid">
            <input
              class="form-check-input"
              type="checkbox"
              :checked="localItemData.image_load == 1"
              @change="toggleImageLoad"
            />
            <span class="form-check-label fw-semibold text-gray-500">
              {{ $t('pages.items.modal.other.checkImage') }}
            </span>
          </label>
        </div>
      </div>

      <!-- Загрузка изображения, если оно не загружено автоматически -->
      <div
        v-if="localItemData.image_load != '1'"
        class="card card-flush mb-7"
      >
        <div class="card-body text-center">
          <ImageUploader
            ref="imageUploaderRef"
            v-model="localItemData.image"
            @file-change="handleFileChange"
          />
        </div>
      </div>
    </div>
    <!--end::Wrapper-->
  </div>
  <!--end::Step 1-->
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { ErrorMessage, Field } from "vee-validate";
import { useI18n } from "vue-i18n";
import type { ItemData } from "@/utils/helpers/itemHelpers";

import GroupMultiselect from "@/components/form/GroupMultiselect.vue";
import MaxLengthIndicator from "@/components/form/MaxLengthIndicator.vue";
import CurrencyInput from "@/components/modals/forms/CurrencyInput.vue";
import ImageUploader from "@/components/files/ImageUploader.vue";

export default defineComponent({
  name: "item-modal-step1",
  components: {
    ErrorMessage,
    Field,
    GroupMultiselect,
    MaxLengthIndicator,
    CurrencyInput,
    ImageUploader,
  },
  props: {
    localItemData: {
      type: Object as () => ItemData,
      required: true
    },
    itemStore: {
      type: Object as () => ItemData,
      required: true
    },
    onReset: {
      type: Function,
      required: true
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const imageUploaderRef = ref<null | { resetImage: () => void }>(null);
    const typedCharsGroup = ref(0); // Счетчик введенных символов в поле группы

    // Переключение состояния загрузки изображения
    const toggleImageLoad = () => {
      props.localItemData.image_load = props.localItemData.image_load === '1' ? '0' : '1';
    };

    // Обработка изменения файла (загрузка изображения)
    const handleFileChange = (file: File) => {
      emit('file-change', file);
    };

    // Обновление количества введенных символов в поле группы
    const updateTypedCharsGroup = (newCharCount: number) => {
      typedCharsGroup.value = newCharCount;
    };

    // Сброс изображения при необходимости
    const handleReset = () => {
      imageUploaderRef.value?.resetImage();
    };

    // Отслеживание сброса формы и сброс значений изображения
    watch(() => props.onReset, (newVal) => {
      if (newVal) {
        handleReset();
      }
    });

    return {
      imageUploaderRef,
      toggleImageLoad,
      handleFileChange,
      typedCharsGroup,
      updateTypedCharsGroup,
    };
  },
});
</script>
