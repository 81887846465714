<template>
  <!--begin::Form-->
  <VForm
    class="form w-100"
    id="kt_login_signin_form"
    @submit="onSubmitLogin"
    :validation-schema="login"
    :initial-values="{ email: 'demo@site.pp', password: '1234567890' }"
  >
    <!--begin::Heading-->
    <div class="text-center mb-10">
      <!--begin::Title-->
      <h1 class="text-gray-900 fw-bolder mb-3">
        {{ $t('pages.authentication.signIn.pageTitle')}}
      </h1>
      <!--end::Title-->
      <!--begin::Subtitle-->
      <div class="text-gray-500 fw-semibold fs-6">
        {{ $t('pages.authentication.signIn.subtitle')}}
      </div>
      <!--end::Subtitle--->
    </div>
    <!--begin::Heading-->
    
    <!--begin::OAuth-->
    <OAuth />
    <!--end::OAuth-->
  
    <!--begin::Separator-->
    <div class="d-flex align-items-center my-10">
      <div class="border-bottom border-gray-300 mw-50 w-100"></div>
      <span class="fw-semibold text-gray-400 fs-8 mx-2">{{ $t('pages.authentication.or')}}</span>
      <div class="border-bottom border-gray-300 mw-50 w-100"></div>
    </div>
    <!--end::Separator-->

<!--     
    <div class="mb-10 bg-light-info p-8 rounded">
      <div class="text-info">
        Use account <strong>demo@site.pp</strong> and password
        <strong>1234567890</strong> to continue.
      </div>
    </div>
 -->
    <!--begin::Input group-->
    <div class="fv-row mb-8">
      <!--begin::Input-->
      <Field
        class="form-control form-control-lg form-control-solid"
        type="text"
        :placeholder="$t('pages.authentication.email')"
        name="email"
        autocomplete="off"
        :validate-on-input="false"
      />
      <!--end::Input-->
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <ErrorMessage name="email" />
        </div>
      </div>
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="fv-row mb-4">
      <!--begin::Input-->
      <Field
        class="form-control form-control-lg form-control-solid"
        type="password"
        :placeholder="$t('pages.authentication.password')"
        name="password"
        autocomplete="off"
        :validate-on-input="false"
      />
      <!--end::Input-->
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <ErrorMessage name="password" />
        </div>
      </div>
    </div>
    <!--end::Input group-->

    <!--begin::Wrapper-->
    <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
      <div></div>
      <!--begin::Link-->
      <router-link to="/password-reset" class="link-primary fw-semibold">
        {{ $t('pages.authentication.signIn.forgotPassword')}}
      </router-link>
      <!--end::Link-->
    </div>
    <!--end::Wrapper-->

    <!--begin::Submit button-->
    <button
      type="submit"
      id="kt_sign_in_submit"
      class="btn btn-lg btn-primary w-100 mb-10"
      :data-kt-indicator="isSubmitting ? 'on' : null"
      :disabled="isSubmitting"
    >
      <span v-if="!isSubmitting" class="indicator-label"> {{ $t('pages.authentication.signIn.button')}} </span>
      <span v-else class="indicator-progress">
        {{ $t('pages.authentication.wait')}}
        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
      </span>
    </button>
    <!--end::Submit button-->
    
    <!--begin::Link-->
    <div class="text-gray-500 text-center fw-semibold fs-6">
      {{ $t('pages.authentication.signIn.noAccount')}}
      <router-link to="/sign-up" class="link-primary fw-semibold ms-1">
        {{ $t('pages.authentication.signIn.signUp')}}
      </router-link>
    </div>
    <!--end::Link-->
  </VForm>
  <!--end::Form-->
</template>

<script lang="ts" setup>
import { ref, onMounted } from "vue";
import { ErrorMessage, Field, Form as VForm } from "vee-validate";
import { useAuthStore, type User } from "@/stores/auth";
import { useRouter } from "vue-router";
import { addToast } from '@/utils/toastManager';
import { useI18n } from "vue-i18n";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import OAuth from "@/components/oauth/OAuth.vue";

const { t } = useI18n();
const userStore = useAuthStore();
const router = useRouter();
const isSubmitting = ref<Boolean>(false);

// Создаем объект валидации формы
const login = Yup.object().shape({
  email: Yup.string()
    .required(t('validation.requiredField', { field: t('pages.authentication.email') }))
    .email().min(6),
  password: Yup.string()
    .required(t('validation.requiredField', { field: t('pages.authentication.password') }))
    .min(8),
});

onMounted(() => {
  // userStore.verifyOauthWithSession(); // Авторизация по сессии для админа
});

// Функция отправки формы
const onSubmitLogin = async (values: { email: string; password: string }) => {
  values = values as User;
  // userStore.logout(); // Очистка существующих ошибок

  isSubmitting.value = true; // Активируем индикатор

  await userStore.login(values); // Отправляем запрос на вход
  
  const error = Object.values(userStore.errors);

  if (error.length === 0) {
    addToast(t('messages.any.success'), t('messages.text.success.signIn'), 'success');
    router.push({ name: "search" });
  } else {
    Swal.fire({
      text: error[0] as string,
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: t('messages.button.tryAgain'),
      heightAuto: false,
      customClass: {
        confirmButton: "btn fw-semibold btn-light-danger",
      },
    }).then(() => {
      userStore.errors = {};
    });

    isSubmitting.value = false; // Деактивируем индикатор
  }
};
</script>
