import { defineStore } from 'pinia';
import ApiService from '@/core/services/ApiService';

interface PriceChartData {
  date: string[];
  price: (number | null)[];
}

interface FetchParams {
  id: number;
  period: string;
}

export const usePriceChartStore = defineStore('priceChart', {
  state: () => ({
    dataObj: {
      date: [] as string[],
      price: [] as (number | null)[]
    } as PriceChartData,
    error: null as string | null,
    isLoading: true as boolean,
  }),

  actions: {
    async fetchData(params: FetchParams) {
      try {
        const { data } = await ApiService.get('pulse.table.price.list', params);

        const fetchedData = data.result;
        
        // Обработка данных: замена пустых значений или NaN на null
        const processedData = fetchedData.price.map((price: string) =>
          price === "" || isNaN(Number(price)) ? null : Number(price)
        );
        this.dataObj.date = fetchedData.date;
        this.dataObj.price = processedData;
        
      } catch ({ response }) {
        this.error = response.data?.error_description;
      } finally {
        this.isLoading = false;
      }
    },
    
    resetLoading() {
      this.isLoading = true;
    },
  },
});
