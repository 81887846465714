<template>
  <!--begin::Toolbar-->
  <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div
      id="kt_app_toolbar_container"
      class="app-container d-flex flex-stack"
      :class="{
        'container-fluid': toolbarWidthFluid,
        'container-xxl': !toolbarWidthFluid,
      }"
    >
      <KTPageTitle />
      <!--begin::Actions-->
      <div class="d-flex align-items-center gap-2 gap-lg-3">
        
        <div
          :class="[
            'btn btn-sm fw-bold',
            isSubscription ? 'btn-info hover-scale' : 'bg-body btn-color-gray-700 btn-active-color-info'
          ]"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_upgrade_plan"
        >
          {{ $t('toolbar.upgradePlan') }}
        </div>
        
      </div>
      <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
  </div>
  <!--end::Toolbar-->
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/auth";
import { useItemStore } from "@/stores/item";

import { toolbarWidthFluid } from "@/layouts/default-layout/config/helper";
import KTPageTitle from "@/layouts/default-layout/components/toolbar/PageTitle.vue";

export default defineComponent({
  name: "layout-toolbar",
  components: {
    KTPageTitle,
  },
  setup() {
    const { t } = useI18n();
    const userStore = useAuthStore();
    const itemStore = useItemStore();

    const isSubscription = computed(() => 
      userStore.isSubscription('search', itemStore.searchs?.length)
    );

    return {
      toolbarWidthFluid,
      isSubscription,
    };
  },
});
</script>