import type { MenuItem } from "@/layouts/default-layout/config/types";

const MainMenuConfig: Array<MenuItem> = [
  {
    pages: [
      {
        heading: "menu.home",
        route: "/dashboard",
        keenthemesIcon: "home-3",
        bootstrapIcon: "bi-house",
      },
      {
        heading: "menu.search",
        route: "/search",
        keenthemesIcon: "magnifier",
        bootstrapIcon: "bi-search",
      },      
      {
        heading: "menu.groups",
        route: "/groups",
        keenthemesIcon: "abstract-26",
        bootstrapIcon: "bi-collection",
      },
    ],
    tabbar: [
      {
        heading: "menu.home",
        route: "/dashboard",
        keenthemesIcon: "home-3",
        bootstrapIcon: "bi-house",
      },
      {
        heading: "menu.search",
        route: "/search",
        keenthemesIcon: "magnifier",
        bootstrapIcon: "bi-search",
      },      
      {
        heading: "menu.groups",
        route: "/groups",
        keenthemesIcon: "abstract-26",
        bootstrapIcon: "bi-collection",
      },
      {
        heading: "menu.setting",
        route: "/account/settings",
        keenthemesIcon: "gear",
        bootstrapIcon: "bi-gear",
      },      
    ],
  },
/*   {
    heading: "System",
    route: "/system",
    pages: [
      {
        heading: "menu.layoutBuilder",
        route: "/builder",
        keenthemesIcon: "switch",
        bootstrapIcon: "bi-layers",
      },
    ],
  }, */
];

export default MainMenuConfig;