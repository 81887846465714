<template>
  <!--begin::Modal - Upgrade plan-->
  <div
    class="modal fade"
    id="kt_modal_upgrade_plan"
    tabindex="-1"
    aria-hidden="true"
  >
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-xl">
      <!--begin::Modal content-->
      <div class="modal-content rounded">
        <!--begin::Modal header-->
        <div class="modal-header justify-content-end border-0 pb-0">
          <!--begin::Close-->
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <KTIcon icon-name="cross" icon-class="fs-1" />
          </div>
          <!--end::Close-->
        </div>
        <!--end::Modal header-->

        <!--begin::Modal body-->
        <div class="modal-body pt-0 pb-15 px-5 px-xl-20">
          <!--begin::Heading-->
          <div class="mb-13 text-center">
            <h1 class="mb-3">{{ $t('modal.upgradePlan.title') }}</h1>
            
            <div class="text-gray-500 fw-semibold fs-5">
              {{ $t('modal.upgradePlan.subtitle') }}
              <a href="#" class="link-primary">{{ $t('modal.upgradePlan.subtitleLink') }}</a>.
            </div>
          </div>
          <!--end::Heading-->

          <!--begin::Plans-->
          <div class="d-flex flex-column">
            <!--begin::Nav group-->
            <div
              class="nav-group nav-group-outline mx-auto"
              data-kt-buttons="true"
            >
              <button
                @click="current = 'month'"
                :class="[current === 'month' && 'active']"
                class="btn btn-color-gray-500 btn-active btn-active-secondary px-6 py-3 me-2"
              >
                {{ $t('modal.upgradePlan.nav.monthly') }}
              </button>
              <button
                @click="current = 'annual'"
                :class="[current === 'annual' && 'active']"
                class="btn btn-color-gray-500 btn-active btn-active-secondary px-6 py-3"
              >
                {{ $t('modal.upgradePlan.nav.annual') }}
              </button>
            </div>
            <!--end::Nav group-->

            <!--begin::Row-->
            <div class="row mt-10">
              <!--begin::Col-->
              <div class="col-lg-6 mb-10 mb-lg-0">
                <!--begin::Tabs-->
                <div class="nav flex-column">
                  <template v-for="(plan, index) in plans" :key="index">
                    <!--begin::Tab link-->
                    <div
                      @click="selected = plan.title"
                      class="nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6"
                      :class="[
                        'btn-active btn-active-light-primary',
                        index !== plans.length - 1 && 'mb-6',
                        selected === plan.title && 'active',
                        plan.disabled && 'disabled'
                      ]"
                      data-bs-toggle="tab"
                      :data-bs-target="`#kt_upgrade_plan_${index}`"
                    >
                      <!--end::Description-->
                      <div class="d-flex align-items-center me-2">
                        <!--begin::Radio-->
                        <div
                          class="form-check form-check-custom form-check-solid form-check-primary me-6"
                        >
                          <input
                            class="form-check-input"
                            type="radio"
                            name="plan"
                            :value="plan.title"
                            :checked="selected === plan.title"
                          />
                        </div>
                        <!--end::Radio-->

                        <!--begin::Info-->
                        <div class="flex-grow-1">
                          <h2
                            class="d-flex align-items-center fs-2 fw-bold flex-wrap"
                          >
                            {{ plan.title }}
                            <span
                              v-if="userSubscriptionType === plan.title"
                              class="badge badge-sm badge-outline badge-primary ms-2 fw-semibold"
                            >{{ $t('modal.upgradePlan.currentPlan') }}</span>
                            <span
                              v-else-if="plan.label"
                              class="badge badge-sm badge-outline ms-2 fw-semibold"
                              :class="[
                              !plan.disabled && 'badge-success',
                              plan.disabled && 'badge-secondary'
                              ]"
                            >{{ plan.label }}</span>
                          </h2>
                          <div class="text-gray-500 fw-semibold">
                            {{ plan.subTitle }}
                          </div>
                        </div>
                        <!--end::Info-->
                      </div>
                      <!--end::Description-->

                      <!--begin::Price-->
                      <div class="ms-5">
                        <button
                          v-if="plan.custom"
                          :class="['btn btn-sm', selected === plan.title ? 'btn-primary' : 'btn-light-primary']"
                        >
                          {{ $t('modal.upgradePlan.btn.findPrice') }}
                        </button>
                        <template v-else>
                          <div class="d-flex d-flex-nowrap align-items-center">
                          <span class="fs-2x fw-bold text-nowrap">
                            {{
                              current === "month"
                                ? plan.priceMonth
                                : plan.priceAnnual
                            }}
                          </span>
                          <span class="mx-1">₽</span>
                          <span class="fs-7 d-flex d-flex-nowrap opacity-50">
                            <span class="me-1">/</span>
                            <span v-if="current === 'month'" data-kt-element="period">{{ $t('modal.upgradePlan.month') }}</span>
                            <span v-else data-kt-element="period">{{ $t('modal.upgradePlan.year') }}</span>
                          </span>
                          </div>
                        </template>
                      </div>
                      <!--end::Price-->
                    </div>
                    <!--end::Tab link-->
                  </template>
                </div>
                <!--end::Tabs-->
              </div>
              <!--end::Col-->

              <!--begin::Col-->
              <div class="col-lg-6">
                <!--begin::Tab content-->
                <div class="tab-content rounded h-100 bg-light p-10">
                  <template v-for="(plan, index) in plans" :key="index">
                    <!--begin::Tab Pane-->
                    <div
                      :class="[selected === plan.title && 'show active']"
                      class="tab-pane fade"
                      :id="`kt_upgrade_plan_${index}`"
                    >
                      <!--begin::Heading-->
                      <div class="pb-5">
                        <h2 class="fw-bold text-gray-900">
                          {{ $t('modal.upgradePlan.question') }} {{ plan.title }}?
                        </h2>

                        <div class="text-gray-500 fw-semibold">
                          {{ plan.description }}
                        </div>
                      </div>
                      <!--end::Heading-->

                      <!--begin::Body-->
                      <div class="pt-1">
                        <template
                          v-for="(feature, i) in plan.features"
                          :key="i"
                        >
                          <template v-if="plan.features">
                            <!--begin::Item-->
                            <div
                              :class="{
                                'mb-7': i !== plan.features.length - 1,
                              }"
                              class="d-flex align-items-center"
                            >
                              <template v-if="feature.supported">
                                <span
                                  class="fw-semibold fs-5 me-4 text-gray-700 flex-grow-1"
                                >
                                  {{ feature.title }}
                                </span>

                                <KTIcon
                                  icon-name="check"
                                  icon-class="fs-1 text-success"
                                />
                              </template>
                              <template v-else>
                                <span
                                  class="fw-semibold fs-5 text-gray-400 flex-grow-1"
                                >
                                  {{ feature.title }}
                                </span>
                                <KTIcon
                                  icon-name="cross"
                                  icon-class="fs-1"
                                />
                              </template>
                            </div>
                            <!--end::Item-->
                          </template>
                        </template>
                      </div>
                      <!--end::Body-->
                    </div>
                    <!--end::Tab Pane-->
                  </template>
                </div>
                <!--end::Tab content-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
          <!--end::Plans-->

          <!--begin::Actions-->
          <div class="d-flex flex-center flex-row-fluid pt-12">
            <button
              type="reset"
              class="btn btn-light me-3"
              data-bs-dismiss="modal"
            >
            {{ $t('modal.upgradePlan.btn.cancel') }}
            </button>

            <button type="submit" class="btn btn-primary">{{ $t('modal.upgradePlan.btn.upgrade') }}</button>
          </div>
          <!--end::Actions-->
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>
  <!--end::Modal - Upgrade plan-->
</template>

<script lang="ts">
import { getAssetPath } from "@/core/helpers/assets";
import { defineComponent, ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useAuthStore } from "@/stores/auth";

interface IPlans {
  title: string;
  subTitle: string;
  description?: string;
  label?: string;
  priceMonth?: string;
  priceAnnual?: string;
  default: boolean;
  custom: boolean;
  features?: Array<{ title: string; supported: boolean }>;
}

export default defineComponent({
  name: "upgrade-plan-modal",
  components: {
    
  },
  setup() {
    const { t } = useI18n();
    const userStore = useAuthStore();
    const selected = ref(null);

    const userSubscriptionType = computed(() => userStore.user?.subscription?.type);

    watch(() => userSubscriptionType.value, (newType) => {
      if (newType) {
        selected.value = newType;
      }
    },
    { immediate: true });
    
    const current = ref<"month" | "annual">("month");
    const plans: Array<IPlans> = [
      {
        title: "Free",
        subTitle: "Бесплатный для теста",
        description: "Вариант для проверки работы сервиса",
        priceMonth: "0",
        priceAnnual: "0",
        default: false,
        disabled: false,
        custom: false,
        features: [
          {
            title: "Отслеживание 1 товара",
            supported: true,
          },
          {
            title: "Обновление каждые 8 часов",
            supported: true,
          },
          {
            title: "Поддержка через эл. почту",
            supported: true,
          },
          {
            title: "Графики изменения цен",
            supported: true,
          },
          {
            title: "Мгновенные уведомления",
            supported: true,
          },
          {
            title: "Обход страниц со списком товаров",
            supported: false,
          },
          {
            title: "Персонализированные рекомендации",
            supported: false,
          },
          {
            title: "Аналитические отчеты",
            supported: false,
          },
          {
            title: "Поиск по названию",
            supported: false,
          },
          {
            title: "Экспорт в CSV или XML",
            supported: false,
          },
          {
            title: "Ранний доступ к новым функциям",
            supported: false,
          },
          {
            title: "Несколько профилей",
            supported: false,
          },
        ],
      },
      {
        title: "Basic",
        subTitle: "Идеально для старта",
        description: "Оптимальный вариант для отслеживания до 10 товаров",
        priceMonth: "349",
        priceAnnual: "3 499",
        default: true,
        disabled: false,
        custom: false,
        features: [
          {
            title: "Отслеживание до 10 товаров",
            supported: true,
          },
          {
            title: "Обновление каждые 6 часов",
            supported: true,
          },
          {
            title: "Поддержка через эл. почту",
            supported: true,
          },
          {
            title: "Графики истории цен за месяц",
            supported: true,
          },
          {
            title: "Мгновенные уведомления",
            supported: true,
          },
          {
            title: "Обход страниц со списком товаров",
            supported: false,
          },
          {
            title: "Персонализированные рекомендации",
            supported: false,
          },
          {
            title: "Аналитические отчеты",
            supported: false,
          },
          {
            title: "Поиск по названию",
            supported: false,
          },
          {
            title: "Экспорт в CSV или XML",
            supported: false,
          },
          {
            title: "Ранний доступ к новым функциям",
            supported: false,
          },
          {
            title: "Несколько профилей",
            supported: false,
          },
        ],
      },
      {
        title: "Pro",
        subTitle: "Для активных пользователей",
        description: "Оптимальный вариант для отслеживания до 50 товаров",
        priceMonth: "999",
        priceAnnual: "9 999",
        label: "Лучший",
        default: false,
        disabled: false,
        custom: false,
        features: [
          {
            title: "Отслеживание до 50 товаров",
            supported: true,
          },
          {
            title: "Обновление каждые 4 часа",
            supported: true,
          },
          {
            title: "Поддержка через эл. почту + чат",
            supported: true,
          },
          {
            title: "Графики истории цен за 3 месяца",
            supported: true,
          },
          {
            title: "Мгновенные уведомления",
            supported: true,
          },
          {
            title: "Обход 1-ой страницы со списком товаров",
            supported: true,
          },
          {
            title: "Персонализированные рекомендации",
            supported: true,
          },
          {
            title: "Аналитические отчеты",
            supported: true,
          },
          {
            title: "Поиск по названию",
            supported: false,
          },
          {
            title: "Экспорт в CSV или XML",
            supported: false,
          },
          {
            title: "Ранний доступ к новым функциям",
            supported: false,
          },
          {
            title: "Несколько профилей",
            supported: false,
          },
        ],
      },
      {
        title: "Premium",
        subTitle: "Для продвинутых пользователей",
        description: "Оптимальный вариант для отслеживания до 250 товаров",
        priceMonth: "3 999",
        priceAnnual: "39 999",
        // label: "Скоро",
        default: false,
        disabled: false,
        custom: false,
        features: [
          {
            title: "Отслеживание до 250 товаров",
            supported: true,
          },
          {
            title: "Обновление каждые 2 часа",
            supported: true,
          },
          {
            title: "Приоритетная поддержка",
            supported: true,
          },
          {
            title: "Графики истории цен за 6 месяцев",
            supported: true,
          },
          {
            title: "Мгновенные уведомления",
            supported: true,
          },
          {
            title: "Обход 3-х страниц со списком товаров",
            supported: true,
          },
          {
            title: "Персонализированные рекомендации",
            supported: true,
          },
          {
            title: "Аналитические отчеты",
            supported: true,
          },
          {
            title: "Поиск по названию",
            supported: true,
          },
          {
            title: "Экспорт в CSV или XML",
            supported: true,
          },
          {
            title: "Ранний доступ к новым функциям",
            supported: true,
          },
          {
            title: "Несколько профилей",
            supported: false,
          },
        ],
      },
      {
        title: "Enterprise",
        subTitle: "Для огранизаций",
        description: "Максимальный доступ ко всем функциям и преимуществам",
        // label: "Скоро",
        default: false,
        disabled: false,
        custom: true,
        features: [
          {
            title: "Неограниченное отслеживание товаров",
            supported: true,
          },
          {
            title: "Обновление каждый час",
            supported: true,
          },
          {
            title: "Приоритетная поддержка 24/7",
            supported: true,
          },
          {
            title: "Графики истории цен за последние 12 месяцев",
            supported: true,
          },
          {
            title: "Мгновенные уведомления",
            supported: true,
          },
          {
            title: "Обход всех страниц со списком товаров",
            supported: true,
          },
          {
            title: "Персонализированные рекомендации",
            supported: true,
          },
          {
            title: "Аналитические отчеты",
            supported: true,
          },
          {
            title: "Поиск по названию",
            supported: true,
          },
          {
            title: "Экспорт в любой формат",
            supported: true,
          },
          {
            title: "Ранний доступ к новым функциям",
            supported: true,
          },
          {
            title: "Несколько профилей для бизнеса",
            supported: true,
          },
        ],
      },
    ];
    
    return {
      plans,
      current,
      selected,
      getAssetPath,
      userSubscriptionType,
    };
  },
});
</script>
