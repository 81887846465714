import { defineStore } from 'pinia';
import i18n from "@/core/plugins/i18n";
import ApiService from '@/core/services/ApiService';

interface FetchParams {
  limit?: number;
  id?: number;
}

interface PlatformChartData {
  date: string[];
  item: (number | null)[];
  link: (number | null)[];
}

export const useItemStore = defineStore('item', {
  state: () => ({
    searchs: [] as any[],
    groups: [] as any[],
    platforms: [] as any[],
    items: [] as any[],
    countSearch: 0 as number,
    countGroup: 0 as number,
    chartPlatforms: {
      date: [] as string[],
      item: [] as (number | null)[],
      link: [] as (number | null)[]
    } as PlatformChartData,
    errors: [] as string[],
  }),

  getters: {
    priceComparison: () => (item: any) => {
      const bestOfferPrice = parseFloat(item.best_offer?.min_price);
      const wishprice = parseFloat(item.wishprice);
      return !isNaN(bestOfferPrice) && !isNaN(wishprice) && wishprice >= bestOfferPrice;
    },
  },

  actions: {
    async fetchSearchData(params: FetchParams): Promise<void> {
      const searchCmd = params?.id
        ? `pulse.table.itemSearch.list?id=${params.id}`
        : "pulse.table.itemSearch.list";

      return await ApiService.get("batch.json", {
        "cmd[getSearch]": searchCmd,
        "cmd[getGroup]": "pulse.table.group.list",
        "cmd[getPlatform]": "pulse.table.platform.list"
      })
      .then(({ data }) => {
        // Обработка данных для поиска
        this.searchs = data.result?.result?.getSearch?.items || [];
        this.countSearch = data.result?.result?.getSearch?.count || 0;
        // Обработка данных для групп
        this.groups = data.result.result?.getGroup?.groups || [];
        this.countGroup = data.result?.result?.getGroup?.count || 0;
        // Обработка данных для платформ
        this.platforms = data.result?.result?.getPlatform;
      })
      .catch(error => this.handlerApiError(error));
    },

    async fetchGroupData(): Promise<void> {
      return await ApiService.get("pulse.table.group.list.json")
        .then(({ data }) => {
          this.groups = data.result?.groups || [];
          this.countGroup = data.result?.count || 0;
        })
        .catch(error => this.handlerApiError(error));
    },

    async fetchItemData(params: FetchParams): Promise<void> {
      return await ApiService.get("pulse.table.items.list.json", params)
        .then(({ data }) => {
          this.items = data.result || [];
        })
        .catch(error => this.handlerApiError(error));
    },

    async fetchPlatformData(): Promise<void> {
      return await ApiService.get("pulse.table.platform.list.json")
        .then(({ data }) => {
          this.platforms = data.result || [];
        })
        .catch(error => this.handlerApiError(error));
    },

    async fetchPlatformChartData(params: FetchParams): Promise<void> {
      return await ApiService.get("pulse.table.platform.chart.json", params)
        .then(({ data }) => {
          this.chartPlatforms = data.result || { date: [], item: [], link: [] };
        })
        .catch(error => this.handlerApiError(error));
    },

    async archiveSearch(params: FetchParams, localizedError: string = ''): Promise<boolean> {
      return await this.performDeleteRequest("pulse.table.itemSearch.archive", params, localizedError);
    },
    
    async clearItems(params: FetchParams, localizedError: string = ''): Promise<boolean> {
      return await this.performDeleteRequest("pulse.table.items.clean", params, localizedError);
    },
    
    async clearAnalytics(params: FetchParams, localizedError: string = ''): Promise<boolean> {
      return await this.performDeleteRequest("pulse.table.price.clean", params, localizedError);
    },

    async deleteItem(params: FetchParams, isGroup: boolean, localizedError: string = ''): Promise<boolean> {
      const deleteUrl = isGroup ? 'pulse.table.group.delete' : 'pulse.table.itemSearch.delete';
      return await this.performDeleteRequest(deleteUrl, params, localizedError);
    },
    
    async addSearch(formData: object): Promise<boolean> {
      return await this.performPostRequest("pulse.table.itemSearch.add", formData, "addSearch");
    },

    async editSearch(formData: object): Promise<boolean> {
      return await this.performPostRequest("pulse.table.itemSearch.edit", formData, "editSearch");
    },

    async addGroup(formData: object): Promise<boolean> {
      return await this.performPostRequest("pulse.table.group.add", formData, "addGroup");
    },

    async editGroup(formData: object): Promise<boolean> {
      return await this.performPostRequest("pulse.table.group.edit", formData, "editGroup");
    },
    
    async performDeleteRequest(url: string, params: FetchParams, localizedError: string = ''): Promise<boolean> {
      return await ApiService.delete(url, params)
        .then(({ data }) => {
          return data.result ? true : false;
        })
        .catch(error => this.handlerApiError(error, localizedError));
    },

    async performPostRequest(url: string, formData: object, localizedError: string): Promise<boolean> {
      return await ApiService.post(url, formData, {
        "Content-Type": "multipart/form-data",
      })
      .then(({ data }) => {
        return data.result ? true : false;
      })
      .catch(error => {
        this.handlerApiError(error, localizedError);
        return false;
      });
    },
        
    setError(error: string | string[]) {
      this.errors = Array.isArray(error) ? error : [error];
    },
    
    handlerApiError(error: any, localizedError: string = '') {
      if (error.response) {
        const errorCode = error.response.data.error;
        const errorDescription =
          i18n.global.t(`messages.text.error.${localizedError}`) ||
          // error.response.data.error_description ||
          i18n.global.t('error.unknown');
    
        // Проверяем, существует ли локализация для errorCode
        const localizedMessage = i18n.global.te(`error.${errorCode}`)
          ? i18n.global.t(`error.${errorCode}`)  // Используем локализованное сообщение
          : errorDescription; // Используем сообщение по умолчанию
    
        this.setError(localizedMessage);
      } else if (error.request) {
        // Сообщение для случая, когда нет ответа от сервера
        this.setError(i18n.global.t('error.noResponseServer'));
      } else {
        // Универсальное сообщение для всех остальных ошибок
        this.setError(i18n.global.t('error.generalError'));
      }
    },
  },
});
