<template>
  <Datatable
    aria-hidden="true"
    class="table align-middle table-row-dashed gy-3 placeholder-glow"
    :header="tableHeader"
    :data="placeholderItems"
    :items-per-page="itemsPerPage"
    :items-per-page-dropdown-enabled="false"
  >
    <template v-slot:title>
      <div class="d-flex align-items-center">
        <div class="symbol symbol-50px placeholder bg-secondary">
          <span class="symbol-label placeholder bg-secondary"></span>
        </div>
        <div class="d-flex flex-column w-100 gap-4 ms-5">
          <div class="rounded-1 placeholder bg-secondary placeholder-lg col-8"></div>
          <div class="rounded-1 placeholder bg-secondary placeholder-sm col-3"></div>
        </div>
      </div>
    </template>
  
    <template v-slot:rating>
      <div class="rounded-1 placeholder bg-secondary col-4"></div>
    </template>
  
    <template v-slot:price>
      <div class="rounded-1 placeholder bg-secondary col-5"></div>
    </template>
    
    <template v-slot:brand>
      <div class="rounded-1 placeholder bg-secondary col-5"></div>
    </template>
    
    <template v-slot:sku>
      <div class="rounded-1 placeholder bg-secondary col-6"></div>
    </template>

    <template v-slot:ai_score>
      <div class="rounded-1 placeholder bg-secondary col-4"></div>
    </template>
  
    <template v-slot:timestamp_x>
      <div class="d-flex flex-column align-items-end gap-2">
        <div class="rounded-1 placeholder bg-secondary placeholder-lg col-5"></div>
        <div class="rounded-1 placeholder bg-secondary placeholder-sm col-7"></div>
      </div>
    </template>
  </Datatable>

</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Datatable from "@/components/kt-datatable/KTDataTable.vue";

export default defineComponent({
  name: 'placeholder-items-list',
  components: {
    Datatable,
  },
  props: {
    length: {
      type: Number,
      required: true,
    },
    tableHeader: {
      type: Object,
      required: false,
    },
    itemsPerPage: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    const placeholderItems = Array.from({ length: props.length }, () => ({}));

    return {
      placeholderItems,
    }
  },
});
</script>