<template>
  <!--begin::Form-->
  <VForm
    class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
    @submit="onSubmitChangePassword"
    id="kt_login_password_new_form"
    :validation-schema="changePassword"
  >
    <!--begin::Heading-->
    <div class="text-center mb-10">
      <!--begin::Title-->
      <h1 class="text-gray-900 fw-bolder mb-3">
        {{ $t('pages.authentication.passwordChange.pageTitle')}}
      </h1>
      <!--end::Title-->
      <!--begin::Subtitle-->
      <div class="text-gray-500 fw-semibold fs-6">
        {{ $t('pages.authentication.passwordChange.subtitle')}}
        <router-link to="/sign-in" class="link-primary fw-semibold ms-1">
          {{ $t('pages.authentication.signUp.signIn')}}
        </router-link>
      </div>
      <!--end::Subtitle--->
    </div>
    <!--begin::Heading-->

    <!--begin::Input group-->
    <div class="fv-row mb-8" data-kt-password-meter="true">
      <!--begin::Wrapper-->
      <div class="mb-1">
        <!--begin::Input wrapper-->
        <div class="position-relative mb-3">
          <Field
            class="form-control form-control-lg form-control-solid"
            type="password"
            :placeholder="$t('pages.authentication.password')"
            name="password"
            autocomplete="off"
            :validate-on-input="false"
          />
          <div class="fv-plugins-message-container">
            <div class="fv-help-block">
              <ErrorMessage name="password" />
            </div>
          </div>
        </div>
        <!--end::Input wrapper-->
        <!--begin::Meter-->
        <div
          class="d-flex align-items-center mb-3"
          data-kt-password-meter-control="highlight"
        >
          <div
            class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
          ></div>
          <div
            class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
          ></div>
          <div
            class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"
          ></div>
          <div
            class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"
          ></div>
        </div>
        <!--end::Meter-->
      </div>
      <!--end::Wrapper-->
      <!--begin::Hint-->
      <div class="text-muted">
        {{ $t('pages.authentication.signUp.textPassword')}}
      </div>
      <!--end::Hint-->
    </div>
    <!--end::Input group--->
    
    <!--begin::Input group-->
    <div class="fv-row mb-8">
      <Field
        class="form-control form-control-lg form-control-solid"
        type="password"
        :placeholder="$t('pages.authentication.confirmPassword')"
        name="confirm_password"
        autocomplete="off"
      />
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <ErrorMessage name="confirm_password" />
        </div>
      </div>
    </div>
    <!--end::Input group-->
    
    <!-- Скрытые поля для email и checkword -->
    <Field type="hidden" name="email" v-model="email" />
    <Field type="hidden" name="checkword" v-model="checkword" />

    <!--begin::Actions-->
    <div class="d-flex flex-nowrap justify-content-center pb-lg-0">
      <button
        type="submit"
        id="kt_sign_up_submit"
        class="btn btn-lg btn-primary w-100 mb-10"
        :data-kt-indicator="isSubmitting ? 'on' : null"
        :disabled="isSubmitting"
      >
        <span v-if="!isSubmitting" class="indicator-label"> {{ $t('buttons.submit')}} </span>
        <span v-else class="indicator-progress">
          {{ $t('pages.authentication.wait')}}
          <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
        </span>
      </button>
    </div>
    <!--end::Actions-->
  </VForm>
  <!--end::Form-->
</template>

<script lang="ts" setup>
import { ref, onMounted, nextTick } from 'vue';
import { ErrorMessage, Field, Form as VForm } from "vee-validate";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
import { PasswordMeterComponent } from "@/assets/ts/components";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";

const { t } = useI18n();
const store = useAuthStore();
const router = useRouter();

const email = ref('');
const checkword = ref('');

const isSubmitting = ref<boolean>(false);

// Создаем объект валидации формы
const changePassword = Yup.object().shape({
  password: Yup.string()
    .required(t('validation.requiredField', { field: t('pages.authentication.password') }))
    .min(8),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password")], t('validation.passwordsMustMatch')),
});

onMounted(() => {
  const urlParams = new URLSearchParams(window.location.search);
  email.value = urlParams.get('email') || '';
  checkword.value = urlParams.get('checkword') || '';
  
  nextTick(() => {
    PasswordMeterComponent.bootstrap();
  });
});

// Функция отправки формы
const onSubmitChangePassword = async (values: any) => {
  values = values as string;

  // Проверка на пустые значения или строки, содержащие только пробелы
  if (!values.email?.trim() || !values.checkword?.trim()) {
    Swal.fire({
      text: t('messages.text.warning.passwordChangeFail'),
      icon: "warning",
      buttonsStyling: false,
      confirmButtonText: t('messages.button.okGotIt'),
      heightAuto: false,
      customClass: {
        confirmButton: "btn fw-semibold btn-light-warning",
      },
    }).then(() => {
      // Переход на страницу при отсутствии email или checkword
      router.push({ name: "sign-in" });
    });
  
    return; // Останавливаем выполнение дальнейшего кода, если данные не валидны
  }

  isSubmitting.value = true; // Активируем индикатор загрузки

  // Отправляем запрос на восстановление пароля
  await store.changePassword(values);

  const error = Object.values(store.errors);

  if (error.length === 0) {
    // Переход на страницу информации успешного восстановления пароля
    router.push({ name: "password-confirmation" });
  } else {
    Swal.fire({
      text: error[0] as string,
      icon: "error",
      buttonsStyling: false,
      confirmButtonText: t('messages.button.tryAgain'),
      heightAuto: false,
      customClass: {
        confirmButton: "btn fw-semibold btn-light-danger",
      },
    }).then(() => {
      store.errors = {};
    });
  }

  isSubmitting.value = false; // Деактивируем индикатор загрузки
};
</script>
