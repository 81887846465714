<template>
  <div @click="confirmAction">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";
import { useItemStore } from "@/stores/item";
import { addToast } from '@/utils/toastManager';
import Swal from "sweetalert2/dist/sweetalert2.js";

// Определение пропсов
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    required: true,
    validator: (value: string) => ['group', 'item'].includes(value),
  },
});

const { t } = useI18n();
const emit = defineEmits(['group-deleted', 'item-deleted']);
const itemStore = useItemStore();

// Функция для подтверждения действия удаления
const confirmAction = () => {
  const isGroup = props.type === 'group';
  
  const successMessage = isGroup
    ? t('messages.text.success.deleteGroup')
    : t('messages.text.success.deleteSearch');
  
  const warningMessage = isGroup
    ? t('messages.text.warning.deleteGroup', { title: props.data.title })
    : t('messages.text.warning.deleteSearch', { title: props.data.title });
    
  const emitEvent = isGroup ? 'group-deleted' : 'item-deleted';

  Swal.fire({
    text: warningMessage,
    icon: 'warning',
    buttonsStyling: false,
    showCancelButton: true,
    confirmButtonText: t('messages.button.delete'),
    cancelButtonText: t('messages.button.noCancel'),
    heightAuto: false,
    customClass: {
      confirmButton: 'btn btn-danger',
      cancelButton: 'btn btn-secondary',
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        await itemStore.deleteItem({ ID: props.data.id }, isGroup, isGroup ? 'deleteGroup' : 'deleteSearch');

        if (!Object.keys(itemStore.errors).length) {
          emit(emitEvent, props.data.id);
          addToast(t('messages.any.success'), successMessage, 'success');
        } else {
          handleError(itemStore.errors);
        }
      } catch (error) {
        handleError(error)
      }
    }
  });
};

// Константа для обработки ошибок
const handleError = (error) => {
  Swal.fire({
    text: error,
    icon: "error",
    buttonsStyling: false,
    confirmButtonText: t('messages.button.okGotIt'),
    heightAuto: false,
    customClass: {
      confirmButton: "btn btn-danger",
    },
  }).then(() => {
    itemStore.errors = [];
  });
}
</script>
