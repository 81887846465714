<template>
  <div class="card card-flush">
    <div class="card-header flex-nowrap py-5 gap-0 gap-md-5">
      <div class="card-title">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label fw-bold fs-3 mb-1">
            {{ $t('pages.searchId.layoutList.titleCard') }}
          </span>
          <span class="text-muted mt-1 fw-semibold fs-7">
            {{ $t('pages.searchId.layoutList.total') }} 
            <SmoothCounter :targetValue="countData" />
          </span>
        </h3>
      </div>
      <div class="card-toolbar">
        <div
          v-if="itemStore.platforms.length > 0"
          class="d-flex flex-nowrap"
        >
          <label class="form-check form-switch form-check-custom mx-6">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="toggleStatus"
            />
            <span class="form-check-label fw-semibold text-gray-500">
              {{ $t('pages.searchId.layoutList.active') }}
            </span>
          </label>
        
          <select
            class="form-select form-select-white form-select-sm fw-bold"
            name="platform"
            v-model="selectedPlatform"
            :aria-label="$t('pages.searchId.layoutList.titleTable.platform')"
          >
            <option value="0" selected>{{ $t('pages.searchId.layoutList.allPlatforms') }}</option>
            <option
              v-for="platform in itemStore.platforms"
              :key="platform.id"
              :value="platform.id"
            >
              {{ platform.title }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="card-body pt-0 pb-5">
      <Datatable
        v-if="!isLoading"
        class="table align-middle table-row-dashed gy-3"
        :header="tableHeader"
        :data="sortedFilteredItems"
        :items-per-page="itemsPerPage"
        :items-per-page-dropdown-enabled="false"
        :emptyTableText="$t('dataTable.emptyTable')"
        @on-sort="handleSort"
      >
        <template v-slot:title="{ row: item }">
          <div class="d-flex align-items-center min-w-200px">
            <a
              :href="item.link"
              :aria-label="item.title"
              class="symbol symbol-50px"
              target="_blank"
            >
              <span 
                class="symbol-label" 
                :style="`background-image: url('${item.image ? item.image : ''}');`">
                {{item.image ? '' : item.title[0]}}
              </span>
            </a>
            <div class="d-flex flex-column ms-5">
              <a
                :href="item.link"
                target="_blank"
                rel="noopener noreferrer"
                :class="[
                  'fw-bold text-hover-primary fs-5 mb-1',
                  isDateWithin24Hours(item.timestamp_x) ? 'text-gray-800' : 'text-gray-500' 
                ]"
                data-kt-ecommerce-category-filter="category_name"
              >
                {{ truncateTitle(item.title) }}
              </a>
              <div class="text-muted fs-7">
                {{ getPlatformTitle(item.platformId) }}
              </div>
            </div>
          </div>
        </template>
        
        <template v-slot:rating="{ row: item }">
          <div>
            <RatingStars
              v-if="item.rating > 0"
              :rating="Number(item.rating)"
              mode="short"
            />
          </div>
        </template>

        <template v-slot:price="{ row: item }">
          <a
            :href="item.link"
            target="_blank"
            rel="noopener noreferrer"
            :class="[
              'fw-bold text-hover-primary fs-6',
              isDateWithin24Hours(item.timestamp_x) ? 'text-gray-700' : 'text-gray-400' 
            ]"
          >
            <PriceFormatter :price="item.price" class="text-nowrap" />
          </a>
        </template>

        <template v-slot:brand="{ row: item }">
          {{ item.brand }}
        </template>

        <template v-slot:sku="{ row: item }">
          <div class="text-muted fw-semibold">
            {{ item.skuId }}
          </div>
        </template>

        <template v-slot:ai_score="{ row: item }">
          <div class="text-muted fw-semibold">
            {{ item.ai_score }}
          </div>
        </template>

        <template v-slot:timestamp_x="{ row: item }">
          <div class="d-flex flex-column">
            <DateFormatter 
              :date="item.timestamp_x" 
              :format="'d/m/Y'"
              :class="[
                'text-nowrap fw-bold',
                isDateWithin24Hours(item.timestamp_x) ? 'text-gray-800' : 'text-gray-500' 
              ]"
            />
            <DateFormatter 
              :date="item.timestamp_x" 
              :format="'в H ч. i мин.'" 
              class="text-nowrap text-muted fw-semibold text-muted fs-7"
            />
          </div>
        </template>
      </Datatable>
      
      <Placeholder
        v-else
        :length="5"
        :table-header="tableHeader"
        :items-per-page="itemsPerPage"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from 'vue-router';
import { useItemStore } from "@/stores/item";

import arraySort from 'array-sort';
import Datatable from "@/components/kt-datatable/KTDataTable.vue";
import PriceFormatter from '@/components/formatters/PriceFormatter.vue';
import DateFormatter from '@/components/formatters/DateFormatter.vue';
import RatingStars from '@/components/RatingStars.vue';
import Placeholder from '@/components/placeholder/ItemsList.vue';
import SmoothCounter from '@/components/formatters/SmoothCounter.vue'

export default defineComponent({
  name: "search-id-list",
  components: {
    Datatable,
    PriceFormatter,
    DateFormatter,
    RatingStars,
    Placeholder,
    SmoothCounter,
  },
  methods: {
    truncateTitle(title) {
      if (title.length > 50) {
        return title.slice(0, 50) + '...';
      }
      return title;
    }
  },
  setup() {
    const { t } = useI18n();
    const itemStore = useItemStore();
    const route = useRoute();
    const itemsPerPage = 25;
    const selectedPlatform = ref('0');
    const toggleStatus = ref(false);
    
    const tableHeader = ref([
      {
        columnName: t('pages.searchId.layoutList.titleTable.items'),
        columnLabel: "title",
        sortEnabled: true,
        columnClass: "min-w-225px",
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.rating'),
        columnLabel: "rating",
        columnClass: "min-w-100px text-end pe-0",
        sortEnabled: true,
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.price'),
        columnLabel: "price",
        columnClass: "min-w-100px text-end pe-0",
        sortEnabled: true,
      },
/*       {
        columnName: t('pages.searchId.layoutList.titleTable.brand'),
        columnLabel: "brand",
        columnClass: "min-w-100px text-end pe-0",
        sortEnabled: true,
      }, */
/*       {
        columnName: t('pages.searchId.layoutList.titleTable.sku'),
        columnLabel: "sku",
        columnClass: "min-w-125px text-end pe-0",
        sortEnabled: false,
      }, */
      {
        columnName: t('pages.searchId.layoutList.titleTable.ai_score'),
        columnLabel: "ai_score",
        columnClass: "min-w-125px text-end pe-0",
        sortEnabled: true,
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.date'),
        columnLabel: "timestamp_x",
        columnClass: "min-w-125px",
        sortEnabled: true,
      },
    ]);
    
    const sortState = ref({ label: null, order: 'asc' });
    
    const handleSort = (sort) => {
      sortState.value = sort;
    };
    
    const sortedFilteredItems = computed(() => {
      const items = filteredItems.value.slice(); // копировать массив перед сортировкой
      
      if (sortState.value.label && items.length > 0) {
        const reverse = sortState.value.order === 'desc';
        const firstItem = items[0][sortState.value.label];
        const isNumeric = !isNaN(parseFloat(firstItem)) && isFinite(firstItem);
    
        if (isNumeric) {
          // Сортируем числа с помощью arraySort
          return arraySort(items, (a, b) => {
            const aValue = parseFloat(a[sortState.value.label]);
            const bValue = parseFloat(b[sortState.value.label]);
    
            return reverse ? bValue - aValue : aValue - bValue;
          });
        } else {
          // Используем arraySort для всех остальных типов данных
          return arraySort(items, sortState.value.label, { reverse });
        }
      }
      return items;
    });
    
    const isDateWithin24Hours = (datetime) => {
      const now = new Date();
        const timestamp = new Date(datetime);
        const diffInMilliseconds = now.getTime() - timestamp.getTime();
        return diffInMilliseconds <= 24 * 60 * 60 * 1000;
    };

    // Фильтруем items на основе условий
    const filteredItems = computed(() => {
      return itemStore.items.filter(item => {
        // Проверка платформы
        const platformMatch = selectedPlatform.value === '0' || item.platformId === selectedPlatform.value;
    
        // Проверка статуса (если переключатель включен или дата в пределах 24 часов)
        const statusMatch = toggleStatus.value || (!toggleStatus.value && isDateWithin24Hours(item.timestamp_x));

        // Проверка aiScore (если aiScore не null и >= item.ai_score)
        const aiScoreMatch = toggleStatus.value || aiScore.value <= item.ai_score;
    
        // Все условия должны быть выполнены
        return platformMatch && statusMatch && aiScoreMatch;
      });
    });

    const searchData = computed(() => {
      const itemIndex = itemStore.searchs.findIndex(item => {
        return Number(item.id) === Number(route.params.id);
      });
      
      return itemIndex !== -1 ? itemStore.searchs[itemIndex] : null;
    });
    
    const aiScore = computed(() => {
      return searchData.value ? searchData.value?.ai_score : null;
    });
    
    const isLoading = computed(() => {
      return (itemStore.items.length > 0 && searchData.value?.ai_score && itemStore.items[0].parentId == Number(route.params.id)) ? false : true;
    });
    
    const countData = computed(() => {
      return !isLoading.value ? filteredItems.value.length : 0;
    });

    const getPlatformTitle = (platformId) => {
      const platform = itemStore.platforms.find(p => p.id === platformId);
      return platform ? platform.title : '';
    };

    const fetchGroupsData = async () => {
      await itemStore.fetchItemData({ id: Number(route.params.id) });
    };

    onMounted(() => {
      fetchGroupsData();
    });
    
    watch(
      () => route.params.id,
      async (newId, oldId) => {
        if (newId !== oldId && newId) {
          await fetchGroupsData(newId);
        }
      }
    );

    watch(() => route.query.clear,
      async (newCleared, oldCleared) => {
        if (newCleared !== oldCleared && newCleared) {
          await fetchGroupsData();
        }
      }
    );
    
    return {
      tableHeader,
      countData,
      itemStore,
      getPlatformTitle,
      selectedPlatform,
      sortedFilteredItems,
      handleSort,
      isLoading,
      itemsPerPage,
      isDateWithin24Hours,
      toggleStatus,
    };
  },
});
</script>