<template>
  <!--begin::Navbar-->
  <div class="card mb-5 mb-xl-10">
    <div class="card-body pt-8 pt-sm-9 pb-0">
      <!--begin::Details-->
      
      <UserInfo v-if="isMobile" />

      <div class="d-flex flex-wrap flex-sm-nowrap mb-3">
        <!--begin: Pic-->
        <div class="me-7 mb-5">
          <div class="symbol symbol-100px symbol-sm-150px symbol-fixed position-relative">
            <span 
              v-if="userStore.user.photo_url"
              class="symbol-label bg-dark text-inverse-info" 
              :style="`background-image: url('${ getAssetUrl(userStore.user.photo_url) }')`"
            ></span>
            <span v-else-if="userStore.user.name" class="fs-1 symbol-label bg-dark text-inverse-info">
              {{ userStore.user.name.slice(0, 1).toUpperCase() }}
            </span>
            <span v-else-if="userStore.user.email" class="fs-1 symbol-label bg-dark text-inverse-info">
              {{ userStore.user.email.slice(0, 1).toUpperCase() }}
            </span>
            <span v-else class="symbol-label bg-dark text-inverse-info">H</span>
          </div>
        </div>
        <!--end::Pic-->

        <!--begin::Info-->
        <div class="flex-grow-1">
          <!--begin::Title-->
          <UserInfo v-if="!isMobile" />
          <!--end::Title-->

          <!--begin::Stats-->
          <div class="d-flex flex-wrap gap-6">
            <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4">
              <div class="d-flex align-items-center">
                <div class="fs-2 fw-bold">
                  <SmoothCounter :targetValue="itemStore.countGroup" />
                  <span class="fw-semibold fs-6 text-gray-500 ms-1">
                    <EndingFormatter :number="itemStore.countGroup" single="группа" few="группы" many="групп" />
                  </span>
                </div>
              </div>
              <div class="d-flex align-items-center w-100 mw-125px mt-2">  
                <div :class="['progress h-6px w-100 me-2', `bg-light-${getColorClass(usedGroupPercentage)}`]">
                  <div 
                    :class="`progress-bar bg-${getColorClass(usedGroupPercentage)}`" 
                    role="progressbar" 
                    :style="{ width: `${usedGroupPercentage}%` }" 
                    :aria-valuenow="usedGroupPercentage" 
                    aria-valuemin="0" 
                    aria-valuemax="100">
                  </div>
                </div>
                <span class="text-gray-500 fw-semibold">
                  <SmoothCounter :targetValue="usedGroupPercentage" suffix="%" />
                </span>
              </div>
            </div>

            <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 ">
              <div class="d-flex align-items-center">
                <div class="fs-2 fw-bold">
                  <SmoothCounter :targetValue="itemStore.countSearch" />
                  <span class="fw-semibold fs-6 text-gray-500 ms-1">
                    <EndingFormatter :number="itemStore.countSearch" single="поиск" few="поиска" many="поисков" />
                  </span>
                </div>
              </div>
              <div class="d-flex align-items-center w-100 mw-125px mt-2">  
                <div :class="['progress h-6px w-100 me-2', `bg-light-${getColorClass(usedSearchPercentage)}`]">
                  <div 
                    :class="`progress-bar bg-${getColorClass(usedSearchPercentage)}`" 
                    role="progressbar" 
                    :style="{ width: `${usedSearchPercentage}%` }" 
                    :aria-valuenow="usedSearchPercentage" 
                    aria-valuemin="0" 
                    aria-valuemax="100">
                  </div>
                </div>
                <span class="text-gray-500 fw-semibold">
                  <SmoothCounter :targetValue="usedSearchPercentage" suffix="%" />
                </span>
              </div>
            </div>
          </div>
          <!--end::Stats-->
        </div>
        <!--end::Info-->
      </div>
      <!--end::Details-->

      <!--begin::Navs-->
      <div class="d-flex overflow-x-auto h-50px">
        <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold flex-nowrap">
          <li class="nav-item">
            <router-link
              to="/account/overview"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t('pages.account.nav.overview') }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/account/settings"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t('pages.account.nav.settings') }}
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/account/notifications"
              class="nav-link text-active-primary me-6"
              active-class="active"
            >
              {{ $t('pages.account.nav.notifications') }}
            </router-link>
          </li>
        </ul>
      </div>
      <!--begin::Navs-->
    </div>
  </div>
  <!--end::Navbar-->
  <router-view></router-view>
</template>

<script lang="ts">
import { getAssetUrl } from "@/core/helpers/assets";
import { defineComponent, onMounted, computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useItemStore } from "@/stores/item";
import { isMobile } from "@/core/helpers/mobile";

import UserInfo from "@/components/account/UserInfo.vue";
import EndingFormatter from "@/components/formatters/EndingFormatter.vue";
import SmoothCounter from '@/components/formatters/SmoothCounter.vue'

export function usePercentage(usedCount: Ref<number>, maxCount: Ref<number>) {
  return computed(() => {
    if (maxCount.value === 0) return 0;
    return Math.round((usedCount.value / maxCount.value) * 100);
  });
}

export default defineComponent({
  name: "kt-account",
  components: {
    UserInfo,
    EndingFormatter,
    SmoothCounter,
  },
  setup() {    
    const userStore = useAuthStore();
    const itemStore = useItemStore();
    
    const usedGroupPercentage = usePercentage(
      computed(() => itemStore.groups?.length),
      computed(() => userStore.user?.subscription?.maxGroups ?? 1)
    );

    const usedSearchPercentage = usePercentage(
      computed(() => itemStore.searchs?.length),
      computed(() => userStore.user?.subscription?.maxSearch ?? 1)
    );
    
    // Функция для определения класса цвета
    const getColorClass = (percentage: number) => {
      if (percentage > 95) return 'danger';
      if (percentage > 75) return 'warning';
      return 'success';
    };

    onMounted(async () => {
      await itemStore.fetchSearchData();
    });

    return {
      isMobile,
      userStore,
      itemStore,
      getAssetUrl,
      usedGroupPercentage,
      usedSearchPercentage,
      getColorClass,
    };
  },
});
</script>
